<template>
  <div class="wrapper">
    <component :is="layout + '-layout'" v-if="layout"></component>

    <teleport to='body' >
      <popup classes="inactivity-popup main-modal" v-if="inactivityPopup" :show-close-icon="false">
        <div class="inactivity-modal">
          <countdown-timer :time="60" @endtime="reload()" />
  
          <div class="secondary-title">Jesteś jeszcze tu?</div>
          <div class="inactivity-buttons d-flex justify-space-between">
            <button class="btn-link transparent-btn" @click="reload()">
              Nowy projekt
            </button>
            <button class="button-action" @click="closePopup()">Kontynuuj</button>
          </div>
        </div>
      </popup>
    </teleport>

    <div v-show="false">
      <v-idle 
        :loop="true" 
        :duration="30" 
        @idle="onidle" 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { useRoute } from 'vue-router'
import AppLayout from '@/layouts/AppLayout.vue'
import { SET_WINDOW_HEIGHT, SET_WINDOW_WIDTH, SET_STORE_NUMBER } from '@/store/mutation-types'
import Popup from '@/components/Popup.vue'
import CountdownTimer from '@/components/CountdownTimer'

export default {
  components: {
    AppLayout,
    Popup,
    CountdownTimer,
  },
  data() {
    return {
      route: useRoute(),
      inactivityPopup: false,
    }
  },
  created() {
    document.title = process.env.VUE_APP_TITLE
    this.setWindowSize()
    this.eventWindowSize()
  },
  computed: {
    layout() {
      return this.route.meta.layout
    },
    routeName() {
      return this.route.name
    },
    ...mapGetters(['isTablet', 'checkHorizontal', 'storeNumber'])
  },
  watch: {
    checkHorizontal() {
      this.setMobileDeviceSize()
    },
  },
  methods: {
    setWindowSize() {
      this.$store.commit(`${SET_WINDOW_WIDTH}`, window.innerWidth)

      this.$store.commit(`${SET_WINDOW_HEIGHT}`, window.innerHeight)
    },

    eventWindowSize() {
      window.addEventListener('resize', (e) => {
        this.setWindowSize()
      })
    },

    onidle() {
      if (this.isTablet) {
        this.inactivityPopup = true
      }
    },

    reload() {
      if(this.storeNumber) {
        window.location.href = process.env.VUE_APP_BATHROOM_MAIN_LINK_DESIGN + `?store=${this.storeNumber}`
      } else {
        window.location.href = process.env.VUE_APP_BATHROOM_MAIN_LINK_DESIGN
      }
    },

    closePopup() {
      this.inactivityPopup = false
    },

    /**
     *  Prevent scale on mobile devices when the keyboard is active
     */
    setMobileDeviceSize() {
      this.$nextTick(() => {
        let viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute("content", `width=device-width, initial-scale=1.0, height=${window.innerHeight}`);
      })
    },

    ...mapMutations([SET_STORE_NUMBER])
  },
}
</script>