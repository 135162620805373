const filteringProjects = (projects, countProjectOfSlide = 8) => {
  let filteredProjects = {}

  for (let i = 0; i < projects.length; i += countProjectOfSlide) {
    filteredProjects[i] = projects.slice(i, i + countProjectOfSlide)
  }

  return filteredProjects
}

export {
  filteringProjects
}