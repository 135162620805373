// Data
export const SET_DATA = 'SET_DATA'
export const SET_PROJECT = 'SET_PROJECT'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_STORE_NUMBER = 'SET_STORE_NUMBER'

// windowSize.js
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH'
export const SET_WINDOW_HEIGHT = 'SET_WINDOW_HEIGHT'

// Filter
export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER'

// Carousel
export const SET_ACTIVE_PROJECTS_SLIDE = 'SET_ACTIVE_PROJECTS_SLIDE'
