<template>
  <transition name="modal">
    <div 
      class="modal-mask" 
      :class="classes" 
      @click="$emit('close')"
    >
      <div class="modal-container" @click.stop>
        <icon
          v-if="showCloseIcon"
          name="cross"
          class="modal-cross"
          @click="$emit('close')"
        />
        <div class="modal-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/Icon.vue'
export default {
  name: 'Popup',
  components: {
    Icon,
  },
  props: {
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    classes: [Object, String],
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins";

.modal-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  padding: 0 $spacing-30;

  &.main-modal {
    z-index: 9998;
  }

  @include media("max", "lg") {
    padding: 0;
  }

  .modal-container {
    position: relative;
    width: 100%;
    max-width: $popup-max-width;
    height: $popup-height;
    display: flex;
    justify-content: center;
    background-color: $dark;
    transition: all 0.3s ease;
    padding: $spacing-65;
    overflow-y: auto;
    overflow-x: hidden;

    @include media("max", "lg") {
      padding: $spacing-60 $spacing-35;
      height: 100%;
    }

    .modal-cross {
      z-index: 99999;
      position: absolute;
      fill: $white;
      right: 3%;
      top: 3%;
      width: $popup-cross-width;
      cursor: pointer;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
