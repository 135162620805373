import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Home from '@/views/Home.vue'
const Projects = () => import('@/views/Projects.vue')
const Project = () => import('@/views/Project.vue')

const routes = [
  {
    path: '/:kiosk?',
    name: 'home',
    component: Home,
    meta: {
      layout: 'app',
    },
  },
  {
    path: '/:kiosk?/projects',
    name: 'projects',
    component: Projects,
    meta: {
      layout: 'app',
    },
  },
  {
    path: '/:kiosk?/projects/project/:code',
    name: 'project',
    component: Project,
    meta: {
      layout: 'app',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if(!store.getters.storeNumber) {
    const storeNumber = to.query.store
    store.commit('SET_STORE_NUMBER', storeNumber)
  }
  
  next();
})

export default router
