<template>
  <div class="countdown-timer">
    <svg
      class="countdown-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="countdown-timer__circle">
        <circle class="countdown-timer__path-elapsed" cx="50" cy="50" r="45" />
        <path
          :stroke-dasharray="circleDasharray"
          class="countdown-timer__path-remaining"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        />
      </g>
    </svg>
    <span class="countdown-timer__label">{{ formattedTimeLeft }}</span>
  </div>
</template>

<script>
const FULL_DASH_ARRAY = 283

export default {
  name: 'CountdownTimer',

  props: {
    time: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      timePassed: 0,
      timerInterval: null
    }
  },

  computed: {
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft
      const minutes = Math.floor(timeLeft / 60)
      let seconds = timeLeft % 60

      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      return `${minutes}:${seconds}`
    },

    timeLeft() {
      return this.time - this.timePassed
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.time
      return rawTimeFraction - (1 / this.time) * (1 - rawTimeFraction)
    }
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp()
      }
    }
  },

  mounted() {
    this.startTimer()
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval)

      this.$emit('endtime')
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
    }
  }
}
</script>

<style scoped lang="scss">

.countdown-timer {
  position: relative;
  width: $countdown-timer-size;
  height: $countdown-timer-size;
  margin-bottom: $countdown-timer-margin-b;

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 1px;
    stroke: $light-gray;
  }

  &__path-remaining {
    stroke-width: $countdown-timer-thin;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
    color: $yellow;
  }

  &__label {
    position: absolute;
    width: $countdown-timer-size;
    height: $countdown-timer-size;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $countdown-timer-font-size;
    font-family: 'Good-Home-Bold';
    color: $light-gray;
  }
}
</style>
