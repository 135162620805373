<template>
  <div class="main-page d-flex" @click="$router.push({ name: 'projects', params: $router.currentRoute.value.params, query: {...$route.query, style: $store.getters.activeFilter} })">
    <div class="main-page-sidebar d-flex align-center">
      <div class="title-block">
        <div class="content">
          <div class="text">
            Zainspiruj się <br />
            i wybierz swoją <br />
            wymarzoną <br />
            łazienkę!
          </div>
          <div class="image">
            <icon name="hand" />
            <span>Dotknij ekranu</span>
          </div>
        </div>
      </div>
      <div class="mobile-content">
        <div class="mobile-text">Zainspiruj się i wybierz swoją wymarzoną łazienkę!</div>
        <div class="image">
          <icon name="hand" />
          <span>Dotknij ekranu</span>
        </div>
      </div>
    </div>
    <div class="main-page-carousel flex align-center justify-end">
      <div class="main-page__logo">
        <img src="@/assets/images/icons/logo.svg" alt="logo image" />
      </div>
      <div class="main-page__image">
        <carousel
          :slides="images"
          :loop="true"
          :preload-images="false"
          :autoplay="{
            delay: 5000,
          }"
          effect="fade"
        >
          <template #default="{ slide }">
            <img :data-src="slide" :data-srcset="slide" src="" class="swiper-lazy" alt="main image" />
            <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </template>
        </carousel>
      </div>
    </div>

    <a v-if="isTablet" :href="goToBackLink" class="back-button" @click.stop>
      <img src="@/assets/images/icons/home_icon.svg" alt="click mouse" />
      <span>Zaprojektuj inny pokój</span>
    </a>

    <!-- Widget -->
    <!-- <div 
      v-if="isTablet"
      class="right-yellow-button" 
      @click.stop="showWidgetPopup"
    >
      <div class="right-yellow-button-image">
        <icon name="line-pen" />
      </div>
      <div class="right-yellow-button-text">
        Umów się na sesję projektowania z Doradcą w sklepie!
      </div>
    </div> -->

    <teleport v-show="showWidgetModal" to="body">
      <popup v-show="showWidgetModal" classes="new-yellow-modal" @close="closePopup">
        <div id="callme360-widget"></div>
      </popup>

      <div v-show="false">
        <v-idle :duration="60" @idle="onidle" />
      </div>
    </teleport>

    <!-- TEMP -> false -->
    <div 
      v-if="isTablet && false"
      class="right-yellow-button" 
      @click.stop="showPopup()"
    >
      <div class="right-yellow-button-image">
        <icon name="line-pen" />
      </div>
      <div class="right-yellow-button-text">
        Umów się na sesję projektowania z Doradcą w sklepie!
      </div>
    </div>

    <teleport v-if="showKioskModal" to="body">
      <popup classes="new-yellow-modal" @close="closePopup">
        <div class="new-yellow-popup">
          <div class="new-yellow-popup-title secondary-title">
            Umów się na projektowanie z doradcą w sklepie
          </div>
          <div class="d-flex new-yellow-content-block">
            <div class="new-modal-white-content">
              <div class="new-modal-white-image">
                <img src="@/assets/images/image-popup.png" />
              </div>
              <div class="new-modal-white-text">
                Zapraszamy do Strefy Inspiracji w naszych sklepach. Umów się na
                spotkanie z naszym doradcą, który w kilku krokach pomoże
                zaplanować Twoją kuchnię i przedstawi jej wizualizację.
              </div>
            </div>
            <div class="qrcode-img-block">
              <img src="@/assets/images/qr-new.png" class="qrcode-img" />
              <div class="flex qrcode-img-text">
                <span><icon name="photoaparat"></icon></span>
                <div class="flex-1">
                  Skieruj aparat swojego smartfona lub użyj czytnika QR aby
                  przejść do strony.
                </div>
              </div>
            </div>
          </div>
        </div>
      </popup>

      <div v-show="false">
        <v-idle :duration="60" @idle="onidle" />
      </div>
    </teleport>
  </div>
</template>

<script>
import Popup from '@/components/Popup'
import Carousel from '@/components/Carousel'
import Icon from '@/components/Icon'
import '@/assets/styles/_animations.scss'

export default {
  components: {
    Carousel,
    Icon,
    Popup,
  },
  data() {
    return {
      showKioskModal: false,
      showWidgetModal: false,
      images: [
        require('@/assets/images/fullscreen/331_d_4.jpg'),
        require('@/assets/images/fullscreen/331_m_1.jpg'),
        require('@/assets/images/fullscreen/331_m_4.jpg'),
        require('@/assets/images/fullscreen/332_d_1.jpg'),
        require('@/assets/images/fullscreen/332_d_4.jpg'),
        require('@/assets/images/fullscreen/332_m_1.jpg'),
        require('@/assets/images/fullscreen/332_m_2.jpg'),
        require('@/assets/images/fullscreen/332_m_3.jpg'),
        require('@/assets/images/fullscreen/332_m_4.jpg'),
        require('@/assets/images/fullscreen/333_d_1.jpg'),
        require('@/assets/images/fullscreen/333_d_2.jpg'),
        require('@/assets/images/fullscreen/333_d_4.jpg'),
        require('@/assets/images/fullscreen/333_m_2.jpg'),
        require('@/assets/images/fullscreen/333_m_3.jpg'),
        require('@/assets/images/fullscreen/335_d_1.jpg'),
        require('@/assets/images/fullscreen/335_m_1.jpg'),
        require('@/assets/images/fullscreen/336_m_1.jpg'),
        require('@/assets/images/fullscreen/338_d_1.jpg'),
        require('@/assets/images/fullscreen/341_d_1.jpg'),
        require('@/assets/images/fullscreen/344_d_2.jpg'),
        require('@/assets/images/fullscreen/347_m_1.jpg'),
        require('@/assets/images/fullscreen/349_d_1.jpg'),
        require('@/assets/images/fullscreen/349_m_1.jpg'),
        require('@/assets/images/fullscreen/351_d_1.jpg'),
        require('@/assets/images/fullscreen/352_d_1.jpg'),
      ],
    }
  },
  computed: {
    isTablet() {
      return this.$store.getters['isTablet']
    },
    mainLink() {
      return process.env.VUE_APP_BATHROOM_MAIN_LINK_DESIGN
    },
    storeNumber() {
      return this.$store.getters['storeNumber']
    },
    goToBackLink() {
      if(this.storeNumber) {
        return `${this.mainLink}?store=${this.storeNumber}`
      }

      return this.mainLink
    }
  },
  methods: {
    onidle() {
      this.closePopup()
    },
    closePopup() {
      this.showKioskModal = false
      this.showWidgetModal = false
    },
    showPopup() {
      this.showKioskModal = true
    },
    showWidgetPopup() {
      this.showWidgetModal = true
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/_mixins';

.main-page {
  min-height: inherit;
  background: $white;
  overflow: hidden;

  @include media("max", "md") {
    flex-direction: column-reverse;

    &:after {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1;
      background: $yellow;
      height: 35rem;
      width: 35rem;
      border-radius: 50%;
      transform: translate(25%, -25%);
    }

    &:before {
      content: '';
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: rgba(255, 255, 255, 0.5);
      height: 18rem;
      width: 18rem;
      border-radius: 50%;
      transform: translate(-20%, 30%);
    }
  }

  @include media("max", "sm") {
    &:after {
      height: 30rem;
      width: 30rem;
    }
  }

  &-sidebar {
    position: relative;
    width: 25%;

    @include media("max", "md") {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      height: 20%;
    }

    .mobile-content {
      display: none;

      @include media("max", "md") {
        display: block;

        .image {
          display: inline-flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          animation: heartbeat 4.5s infinite;
          color: $dark;
          position: fixed;
          bottom: 2rem;
          left: 3rem;

          svg {
            width: 4rem;
            font-size: $font-size-17;
            margin-bottom: $main-spacing;
          }
        }
      }
    }

    .mobile-text {
      font-size: $font-size-30;
      color: $dark;
      font-family: 'Good-Home-Bold';
      max-width: 80%;
      margin: 0 auto;
    }
  }

  &-carousel {
    position: relative;
    width: 75%;
  
    @include media("max", "md") {
      width: 100%;
      height: 80vh;
    }
  }

  .title-block {
    position: absolute;
    border-radius: $radius-full;
    background: $yellow;
    width: $main-page-circle-size;
    height: $main-page-circle-size;
    color: $dark;
    transform: translateX(45%);
    font-family: 'Good-Home-Bold';
    z-index: 2;

    @include media("max", "md") {
      display: none;
    }

    .content {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      transform: translateX(-10%);

      .text {
        font-size: $big-text;
      }

      .image {
        position: absolute;
        bottom: -8rem;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        animation: heartbeat 4.5s infinite;

        svg {
          width: 6rem;
          margin-bottom: $main-spacing;
          fill: $dark;
        }
      }
    }
  }

  &__logo {
    position: absolute;
    right: 3%;
    top: 5%;
    width: $main-page-logo-width;
    z-index: 2;

    @include media("max", "md") {
      position: fixed;
      width: 12rem;
      bottom: 2rem;
      right: 2rem;
      top: inherit;
    }
  }

  &__image {
    position: relative;
    height: 100%;

    .swiper {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
    }
  }
}

.right-yellow-button {
  background: $yellow;
  width: $main-page-kiosk-button-size;
  height: $main-page-kiosk-button-size;
  border-radius: 100% 0 0 0;
  box-shadow: 0 0 0 2.5rem rgb(252 218 5 / 50%);
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  @include media("max", "md") {
    width: 14rem;
    height: 13rem;
    position: fixed;
    top: 50%;
    border-radius: 50% 0 0 50%;
    padding-left: $main-spacing;
  }
}

.right-yellow-button-image {
  width: $main-page-kiosk-image-size;
  height: $main-page-kiosk-image-size;
}

.right-yellow-button svg {
  height: 85%;
  padding-left: $spacing-25;

  @include media("max", "md") {
    padding-left: 0;
  }
}

.right-yellow-button-text {
  font-size: $font-size-15;
  color: $dark;
  line-height: $line-height-lg;
  text-align: center;
  text-transform: uppercase;
  margin: $main-spacing 0 $spacing-md;
  max-width: $main-page-kiosk-button-text-max-width;

  @include media("max", "md") {
    font-size: 0.8rem;
    max-width: 12rem;
    line-height: $line-height-sm;
  }
}

.modal-mask.new-yellow-modal .modal-container {
  max-width: $kiosk-main-page-modal-max-width;
  height: $kiosk-main-page-modal-height;
}

.new-yellow-popup {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding: $spacing-40 $spacing-120;
  background-color: $dark-gray;

  @include media("max", "lg") {
    bottom: inherit;
    padding: $spacing-40 $spacing-md;
  }
}

.new-yellow-popup-title {
  text-align: center;
  z-index: 1;
  max-width: 85%;
  margin: 0 auto $spacing-60;
  font-size: $font-size-30;

  @include media("max", "lg") {
    margin: 0 auto $spacing-35;
  }
}

.new-yellow-popup:before {
  content: '';
  position: absolute;
  width: 24rem;
  height: 100%;
  left: 0;
  top: 0;
  background: $dark;
  z-index: 1;
}

.new-yellow-content-block {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;

  @include media("max", "lg") {
    flex-direction: column;
    align-items: center;
  }
}

.new-modal-white-content {
  width: 50%;
  max-width: 27rem;

  @include media("max", "lg") {
    width: 100%;
    max-width: 60%;
    margin-bottom: $spacing-50;
  }
}

.qrcode-img-block {
  width: 50%;
  max-width: 25.8rem;

  @include media("max", "lg") {
    text-align: center;
    max-width: 60%;
  }
}

.qrcode-img-text,
.new-modal-white-text {
  margin: $spacing-md 0 0 0;
  font-size: $xs-font-size;
  line-height: $line-height-md;
  font-weight: 400;
  padding-right: $spacing-md;

  @include media("max", "lg") {
    padding-right: 0;
  }
}

.qrcode-img-text {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include media("max", "lg") {
    text-align: left;
  }
}

.qrcode-img-text span {
  display: block;
  width: 17.5rem;
  height: 6.5rem;
  background: $dark;
  border-radius: 50%;
  margin-right: $spacing-md;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 7rem;

  @include media("max", "sm") {
    display: none;
  }
}

.qrcode-img-text span svg {
  width: 3.5rem;
  fill: $gray;
}

.new-modal-white-image img {
  width: 100%;
}

.qrcode-img-block img {
  max-width: 16rem;
}

.back-button {
  position: fixed;
  top: 4rem;
  left: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Good-Home-Bold';
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  color: #434345;

  @include media("max", "md") {
    top: 0.5rem;
    z-index: 2;
  }

  img {
    width: 2.5rem;
    margin-right: 0.5rem;
  }

  span {
    margin-top: 0.5rem;
  }
}

html *,html *::before,html *::after{box-sizing:inherit}

#callme360-widget #appointment-wrap {
  margin: 0px !important;
  display: flex;
  flex-direction: column;
  flex: 1;
}
#callme360-widget #appointment-wrap #booker-loader {
  flex: 1;
}
#callme360-widget #appointment-wrap.page-time .appointment-header-wrap {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
}
#callme360-widget #appointment-wrap.page-time .appointment-header-wrap .booker-tabs {
  display: none;
}

#callme360-widget #appointment-wrap .booker-tabs {
  flex-wrap: wrap;
}

#callme360-widget #appointment-wrap.app-contact form .zend_form .wrap-content.last-content .app-regulations div a {
  pointer-events: none;
}
#callme360-widget #appointment-wrap.success-page #callme360-booker-thankyou-kiosk {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 1.25em;
  justify-content: center;
}
#callme360-widget #appointment-wrap.success-page #callme360-booker-thankyou-kiosk h1 {
  font-size: 2.5em;
}
#callme360-widget #appointment-wrap.success-page #callme360-booker-thankyou-kiosk .callme360-booker-online-only {
  display: none;
}

.hg-theme-default .hg-button.hg-button-space {
  flex-grow: 5;
}
.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
  max-width: none;
}
</style>