<template>
  <swiper
    :effect="effect"
    :modules="swiperModules"
    :loop="loop"
    :slides-per-view="slidesPerView"
    :autoplay="autoplay"
    :direction="direction"
    :mousewheel="mousewheel"
    :navigation="navigation"
    :initial-slide="initialSlide"
    :space-between="spaceBetween"
    :watchOverflow="true"
    :preload-images="preloadImages"
    :lazy="lazy"
    @swiper="setSwiperInstance"
  >
    <swiper-slide v-for="(slide, index) in slides" :key="index" :class="{'swiper-no-swiping': slides.length <= 1}">
      <slot :slide="slide" />
    </swiper-slide>
  </swiper>
</template>

<script>
import { EffectFade, Autoplay, Navigation, Mousewheel, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import 'swiper/css/lazy'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: [Array, Object],
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: [Boolean, Object],
      default: false,
    },
    slidesPerView: {
      type: [Number, String],
      default: 1,
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    mousewheel: {
      type: Boolean,
      default: false,
    },
    navigation: {
      type: [Object, Boolean],
      default: false,
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    spaceBetween: {
      type: Number,
      default: 35,
    },
    preloadImages: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: [Boolean, Object],
      default: {
        loadPrevNext: true,
        loadOnTransitionStart: true,
      }
    },
    effect: {
      type: String,
      default: 'slide',
    },
  },
  data() {
    return {
      swiperModules: [EffectFade, Autoplay, Navigation, Mousewheel, Lazy],
      swiper: null,
    }
  },
  methods: {
    setSwiperInstance(swiper) {
      this.swiper = swiper
    },
  },
  watch: {
    slides: {
      deep: true,
      handler() {
        this.swiper.activeIndex = 0
      },
    },
  },
}
</script>