import { createApp } from 'vue'
import App from './App.vue'
import 'vuetify/styles'
import '@/assets/styles/main.scss'
import router from './router'
import store from './store'
import axios from 'axios'
import Vidle from 'v-idle-3'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

// Vuetify
import { createVuetify } from 'vuetify'
const vuetify = createVuetify()

const app = createApp(App)
app.config.globalProperties.$axios = axios
app.use(store)
app.use(router)
app.use(vuetify)
app.use(Vidle);
app.mount('#app')