<template>
  <svg v-if="name === 'arrow'" width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 4.99623L1.97595 9.73476C1.63831 10.0884 1.09088 10.0884 0.753233 9.73476C0.415589 9.38111 0.415589 8.80773 0.753233 8.45408L5.27728 3.71555L6.5 4.99623Z"/>
    <path d="M6.5 5.00377L1.97595 0.265238C1.63831 -0.0884129 1.09088 -0.0884131 0.753234 0.265238C0.41559 0.618889 0.41559 1.19227 0.753234 1.54592L5.27728 6.28445L6.5 5.00377Z"/>
  </svg>
  <svg v-else-if="name === 'link'" width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.8342 11.8331H6.16756C4.55367 11.8331 3.17817 11.2642 2.04106 10.1263C0.903173 8.98919 0.334229 7.61369 0.334229 5.9998C0.334229 4.38592 0.903173 3.01003 2.04106 1.87214C3.17817 0.735029 4.55367 0.166473 6.16756 0.166473H10.8342V2.49981H6.16756C5.19534 2.49981 4.36895 2.84008 3.68839 3.52064C3.00784 4.20119 2.66756 5.02758 2.66756 5.9998C2.66756 6.97203 3.00784 7.79842 3.68839 8.47897C4.36895 9.15953 5.19534 9.4998 6.16756 9.4998H10.8342V11.8331ZM7.33423 7.16647V4.83314H16.6676V7.16647H7.33423ZM13.1676 11.8331V9.4998H17.8342C18.8064 9.4998 19.6328 9.15953 20.3134 8.47897C20.9939 7.79842 21.3342 6.97203 21.3342 5.9998C21.3342 5.02758 20.9939 4.20119 20.3134 3.52064C19.6328 2.84008 18.8064 2.49981 17.8342 2.49981H13.1676V0.166473H17.8342C19.4481 0.166473 20.824 0.735029 21.9619 1.87214C23.099 3.01003 23.6676 4.38592 23.6676 5.9998C23.6676 7.61369 23.099 8.98919 21.9619 10.1263C20.824 11.2642 19.4481 11.8331 17.8342 11.8331H13.1676Z"/>
  </svg>
  <svg v-else-if="name === 'cross'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.6 29.82">
    <path d="M6.08,34.47h0a2.9,2.9,0,0,1,0-4l14-14,14,14a2.89,2.89,0,0,1,0,4h0a2.82,2.82,0,0,1-4,0l-9.94-10-9.94,10A2.83,2.83,0,0,1,6.08,34.47Z" transform="translate(-5.27 -5.51)"/>
    <path d="M34.05,6.37h0a2.89,2.89,0,0,1,0,4.05l-14,14-14-14a2.9,2.9,0,0,1,0-4h0a2.83,2.83,0,0,1,4.05,0l9.94,10L30,6.35A2.82,2.82,0,0,1,34.05,6.37Z" transform="translate(-5.27 -5.51)"/>
  </svg>
  <svg v-else-if="name === 'hand'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.38 31.35">
    <path d="M12.36,13.06A.91.91,0,0,0,13.11,12a4.62,4.62,0,1,1,9.11,0A.92.92,0,1,0,24,12.3a6.46,6.46,0,1,0-12.73,0A.92.92,0,0,0,12.36,13.06Z" transform="translate(-9.03 -4.75)"/>
    <path d="M27.44,19.48a2.76,2.76,0,0,0-3.68-1.22,2.74,2.74,0,0,0-3.35-1.36V11.28a2.81,2.81,0,0,0-2.69-2.8,2.76,2.76,0,0,0-2.84,2.76V25.39L13.8,24.26a2.78,2.78,0,0,0-3.86-.17A2.74,2.74,0,0,0,9.79,28L16,34.64a4.58,4.58,0,0,0,3.35,1.45h7.48a4.59,4.59,0,0,0,4.59-4.59V22A2.76,2.76,0,0,0,27.44,19.48Zm2.13,12a2.76,2.76,0,0,1-2.75,2.76H19.34a2.78,2.78,0,0,1-2-.87l-6.21-6.62a.92.92,0,0,1,.05-1.32,1,1,0,0,1,1.31.07l2.66,2.79a.92.92,0,0,0,1.58-.63V11.24a.92.92,0,0,1,1-.92.94.94,0,0,1,.9,1c0,10.1,0,5.52,0,11a.93.93,0,0,0,1.86,0V19.33a.92.92,0,0,1,1.82.17v2.76a.92.92,0,1,0,1.83,0V20.73a.92.92,0,0,1,1.84,0v1.53a.92.92,0,0,0,1.84,0V22a.92.92,0,1,1,1.83,0Z" transform="translate(-9.03 -4.75)"/>
  </svg>
  <svg v-else-if="name === 'search'" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.15 16.375L9.6375 10.8625C9.2 11.2125 8.69687 11.4896 8.12812 11.6937C7.55937 11.8979 6.95417 12 6.3125 12C4.72292 12 3.37775 11.4496 2.277 10.3489C1.17567 9.24754 0.625 7.90208 0.625 6.3125C0.625 4.72292 1.17567 3.37746 2.277 2.27613C3.37775 1.17538 4.72292 0.625 6.3125 0.625C7.90208 0.625 9.24754 1.17538 10.3489 2.27613C11.4496 3.37746 12 4.72292 12 6.3125C12 6.95417 11.8979 7.55937 11.6937 8.12812C11.4896 8.69687 11.2125 9.2 10.8625 9.6375L16.375 15.15L15.15 16.375ZM6.3125 10.25C7.40625 10.25 8.33608 9.86733 9.102 9.102C9.86733 8.33608 10.25 7.40625 10.25 6.3125C10.25 5.21875 9.86733 4.28892 9.102 3.523C8.33608 2.75767 7.40625 2.375 6.3125 2.375C5.21875 2.375 4.28892 2.75767 3.523 3.523C2.75767 4.28892 2.375 5.21875 2.375 6.3125C2.375 7.40625 2.75767 8.33608 3.523 9.102C4.28892 9.86733 5.21875 10.25 6.3125 10.25Z" fill="#D1D3D5"/>
  </svg>
  <svg v-else-if="name === 'line-pen'" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.80119 49.9903C0.80709 49.9903 0 49.1832 0 48.1892V1.79134C0 1.05316 0.442915 0.403545 1.11221 0.137795C1.33859 0.0393707 1.56497 0 1.80119 0C2.28347 0 2.73623 0.187009 3.07088 0.531498L30.5119 27.9725L49.4588 46.9293C49.9805 47.4411 50.138 48.2089 49.8623 48.888C49.577 49.5573 48.9273 50.0002 48.199 50.0002H1.80119V49.9903ZM1.57481 1.66339V48.1892C1.57481 48.3171 1.67323 48.4155 1.80119 48.4155H48.4156V48.2285C48.4254 48.1301 48.3762 48.0611 48.3466 48.0316L27.8544 27.5394C27.8347 27.5198 27.8249 27.5001 27.8052 27.4804L1.96851 1.64371C1.92914 1.5945 1.87009 1.55512 1.80119 1.55512H1.74213L1.57481 1.66339Z" fill="#414042"/>
    <path d="M9.70496 41.0629C9.27185 41.0629 8.91754 40.7085 8.91754 40.2755V20.8659C8.91754 20.5511 9.11439 20.2656 9.40968 20.1475C9.50805 20.1081 9.6065 20.0884 9.70496 20.0884C9.91166 20.0884 10.1184 20.1671 10.266 20.3247L29.6755 39.7341C29.892 39.9605 29.9609 40.305 29.8428 40.5806C29.7247 40.8758 29.4393 41.0728 29.1144 41.0728H9.70496V41.0629ZM10.4923 39.498H27.2149L10.4923 22.7754V39.498Z" fill="#414042"/>
    <path d="M44.1831 32.6475C43.7107 32.6475 43.2677 32.4605 42.9331 32.1357L21.2007 10.3836C21.1417 10.3246 21.0826 10.2459 21.0531 10.1671L18.6121 2.3915L18.6023 2.38166C18.4644 2.08638 18.5235 1.74189 18.7598 1.50567C18.9074 1.35803 19.1043 1.2793 19.311 1.2793C19.429 1.2793 19.5374 1.29898 19.6357 1.34819L19.6456 1.35803L27.5197 3.70056C27.5983 3.73993 27.6771 3.78914 27.7362 3.8482L49.4783 25.5904C49.813 25.925 50 26.3679 50 26.8404C50 27.3128 49.813 27.7557 49.4783 28.0904L45.4331 32.1357C45.0886 32.4605 44.6456 32.6475 44.1831 32.6475ZM41.3386 28.3267L44.1733 31.1613L48.4942 26.8404L45.6595 24.0058L41.3386 28.3267ZM22.8543 9.83248L40.2264 27.2144L44.5473 22.8935L27.185 5.52143L22.8543 9.83248ZM21.9586 8.50372L25.8562 4.61592L20.0098 2.76551L21.9586 8.50372Z" fill="#414042"/>
  </svg>
  <svg v-else-if="name === 'photoaparat'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.69 18.07">
    <g><g id="Grid">
        <path id="photo-camera-5" class="cls-1" d="M4.52,1.81H1.81V.9H4.52Zm9.87,0,.74,1.1a3.59,3.59,0,0,0,3,1.61h1.75V16.26H1.81V4.52H5.36a3.59,3.59,0,0,0,3-1.61l.74-1.1Zm1-1.81H8.13L6.86,1.91a1.8,1.8,0,0,1-1.5.8H0V18.07H21.69V2.71H18.13a1.82,1.82,0,0,1-1.5-.8ZM5.42,7.23a.91.91,0,1,0-.9.9A.9.9,0,0,0,5.42,7.23Zm6.33,0A2.71,2.71,0,1,1,9,9.94,2.71,2.71,0,0,1,11.75,7.23Zm0-1.81a4.52,4.52,0,1,0,4.51,4.52A4.52,4.52,0,0,0,11.75,5.42Z"/>
      </g>
    </g>
  </svg>
  <svg v-else-if="name === 'arrow-back'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.02 149.64">
    <polygon points="145.02 64.24 40.52 64.24 89.79 14.97 74.82 0 14.97 59.85 0 74.82 14.97 89.79 14.97 89.79 74.82 149.64 89.79 134.67 40.52 85.4 145.02 85.4 145.02 64.24"/>
  </svg>
  <svg v-else-if="name === 'gray-icon-catorama'" data-name="Castorama gray icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.73 153.94" class="casto-c-gray">
    <path data-v-200f20d6="" id="casto-c-gray-path" d="M448.5,310.47c-8.8,8.28-17.3,13.84-29.8,13.84-18.85,0-32.54-14.88-32.54-35.46v-.47c0-19.65,13.67-34.95,31-34.95,13.21,0,21.35,5.71,29.49,14.27l1.3,1.36,25.87-27.77-1.05-1.18C458.34,224,441.33,216.9,417.39,216.9c-20.24,0-38.8,7.41-52.21,21.18a71.53,71.53,0,0,0-20.47,50.77v.5a71,71,0,0,0,20.53,50.53c13.35,13.52,31.72,21,51.66,21,29.47,0,44.62-12.12,56.61-25.21l1.12-1.29-24.87-25.12Z" transform="translate(-339.71 -211.9)" class="casto-c-gray-path"></path>
  </svg>
  <svg v-else-if="name === 'blue-icon-catorama'" data-name="Castorama blue icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.73 153.94" class="casto-c-blue">
    <path data-v-200f20d6="" id="casto-c-blue-path" d="M137.8,32.8l-4.6-4.7C118.6,12.1,101.6,5,77.7,5c-20.2,0-38.8,7.4-52.2,21.2 C12.2,39.7,4.8,58,5,76.9v0.5c-0.2,18.9,7.2,37.1,20.5,50.5c13.4,13.5,31.7,21,51.7,21c29.5,0,44.6-12.1,56.6-25.2l1.1-1.3L110,97.3 l-1.2,1.2c-8.8,8.3-17.3,13.8-29.8,13.8c-18.9,0-32.5-14.9-32.5-35.5v-0.5c0-19.6,13.7-34.9,31-34.9c13.2,0,21.4,5.7,29.5,14.3 l1.3,1.4l25.9-27.8" class="casto-c-blue-path"></path>
  </svg>
</template>

<script>
export default {
  props: {
    name: String,
  },
}
</script>
