import { createStore } from 'vuex'
import * as types from './mutation-types'
import router from '@/router'
import axios from 'axios'
import { filteringProjects } from '@/utils/filteringProjects'

export default createStore({
  state: {
    activeFilterId: 0,
    activeProjectsSlide: 0,
    windowWidth: 0,
    windowHeight: 0,
    data: null,
    filteredProjects: {},
    project: null,
    isLoaded: false,
    search: '',
    filters: [
      {
        id: 0,
        name: "Wszystkie",
        order: 0,
        preview_image: {
          id: 2524,
          url: require('@/assets/images/styl/all_styles.svg'),
          file: {
            name: "all_styles.svg",
            type: "image/svg"
          }
        }
      },
    ],
    storeNumber: null,
  },
  getters: {
    isTablet: () => !!router.currentRoute.value.params.kiosk,
    checkHorizontal: (state) => state.windowHeight > state.windowWidth,
    activeFilter: state => state.activeFilterId,
    activeProjectsSlide: state => state.activeProjectsSlide,
    data: state => state.data,
    filteredProjects: state => state.filteredProjects,
    project: state => state.project,
    isLoaded: state => state.isLoaded,
    filters: state => state.filters,
    searchValue: state => state.search,
    storeNumber: state => state.storeNumber
  },
  mutations: {
    [types.SET_FILTERS](state, payload) {
      state.filters = [...state.filters, ...payload]
    },

    [types.SET_SEARCH](state, payload) {
      state.search = payload
    },

    [types.SET_DATA](state, payload) {
      state.data = payload
      state.filteredProjects = filteringProjects(payload)
      state.isLoaded = true
    },

    [types.SET_PROJECT](state, payload) {
      state.project = payload
    },

    [types.SET_ACTIVE_FILTER](state, payload) {
      state.activeFilterId = payload
    },

    [types.SET_ACTIVE_PROJECTS_SLIDE](state, payload) {
      state.activeProjectsSlide = payload
    },

    [types.SET_WINDOW_WIDTH](state, payload) {
      state.windowWidth = payload
    },
  
    [types.SET_WINDOW_HEIGHT](state, payload) {
      state.windowHeight = payload
    },
    [types.SET_STORE_NUMBER](state, payload) {
      state.storeNumber = payload
    }
  },
  actions: {
    async getData({getters, commit}) {
      let params = {
        style: getters.activeFilter,
        search: getters.searchValue,
      }

      if(getters.activeFilter === 0) delete params.style
      if(!getters.searchValue) delete params.search

      const response = await axios.get('/collection', { params })

      commit('SET_DATA', response.data.data)
      commit('SET_ACTIVE_PROJECTS_SLIDE', 0)
    },
    async getProject({commit}, code) {
      const response = await axios.get(`/collection/${code}`)
      commit('SET_PROJECT', response.data.data)
    },
    async getFilters({commit}) {
      const response = await axios.get('/style')
      commit('SET_FILTERS', response.data.data)
    },
    async sendEmail(_, {id, email}) {
      try {
        await axios.post(`/api/collection/email/${id}`, {email: email})
        return Promise.resolve()
      } catch (error) {
        return Promise.reject()
      }
    }
  },
  modules: {
  }
})
